<template>
  <div class="user-management-wrap">
    <!-- <side-bar :treeData="list" />
    <section class="user-management-content"> -->
      <page-header></page-header>
      <router-view />
    <!-- </section> -->
  </div>
</template>
<script>
import SideBar from '@/components/SideBar'
import pageHeader from '@/components/pageHeader'
export default {
  components: {
    SideBar, pageHeader
  },
  data () {
    return {

    }
  }
}
</script>

<style lang="scss">
@import '@/styles/index';
.user-management-wrap {
  width: 100%;
  min-height: 100%;
 /* padding: 30px 30px;
  display: flex;
  .user-management-content {
    width: 100%;
    min-height: $contentHeight;
    margin-left: 30px;
  } */
}
</style>
